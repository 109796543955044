@import "../../lib/variables.scss";
.list-page-nav
{
  margin: 1em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-page-nav div
{
  display:inline-block;
  vertical-align:  middle;
}
.page-nav-btn.prev
{
  margin-right: 0;
}
.page-nav-btn.next
{
  margin-left: 0;
}
.page-nav-btn.disabled
{
  color:#bbb;
}
.page-nav-btn:not(.disabled):hover
{
  text-shadow:0 0 1px black;
}
.page-director
{
  font-size:1.2em;
  padding:0 0.25em;
  cursor: pointer;
  line-height: 1em;
}
.page-director.curr-page
{
  color: $purple-main;
}
.page-director:hover
{
  color: $purple-darker;
}
.purple-background
{
  .page-director.curr-page
  {
    color: $purple-light;
  }
  .page-director:hover
  {
    color: $purple-light;
  }
}
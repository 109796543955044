.json-editor{
    padding: 0 0.5em;
    display: block;
    border-right: 2px solid black;
    width: fit-content;

    input[type="text"]{
        display: block;
        position: relative !important;
        background: white !important;
        border: 1px solid #666 !important;
    }
    label{
        display: block;
        span{
            margin-left: 0.5em;
        }
    }
    .embedded-object{
        background-color: #00000010;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1em;
        padding-right: 0.5em;
    }
    .array_entry_wrap{
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        align-items: flex-start;
        margin-bottom: 1em;
    }
    .add-array-entry-button{
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        align-items: flex-start;
    }
    .add-array-entry-button:hover{
        text-decoration: underline;
    }
    .delete-array-entry-button:hover{
        color: #c00;
    }
    .react-date-picker{
        background: white;
        input[type="number"]{
            border:none;
        }
    }

    .boolean-field-wrapper {
        display: flex;
        gap: 0.5rem;
        padding-inline-start: 1rem;
    }
}
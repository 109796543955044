.condition-builder{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: fit-content;

  input, select{
    border-radius: 0;
    border: none;
    outline: none;
  }
  .condition-column-selector, .condition-filter-selector, .filter-input-wrap{
    border: 1px solid black;
    background: white;
  }
  .condition-column-selector, .condition-filter-selector, .filter-input-wrap, .inline-condition-input-label{
    font-size: 14px;
    margin-left: 1em;
    display: block;
    line-height: 1.5em;
    height: 2em;
    padding: 0 3px;
    box-sizing: border-box;
  }
  .inline-condition-input-label {
    display: inline-block;
  }
  .react-date-picker__wrapper{
    border: none;
  }

  .filter-one-option {
      background-color: white;
      border: 1px solid #bbb;
      width: fit-content;
      padding: 1px 4px 1px 10px;
      margin-inline-end: 10px;
  }
}
.table-browser-action{
  display: block;
  font-size: 1em;
  line-height: 1.25em;
  height: fit-content;
  align-self: center;
  padding: 0.1em 0.5em;
  transition: background-color 0.15s, box-shadow 0.15s;
}
.table-browser-action:hover{
  background-color: #90278e10;
  box-shadow: 0 0 6px 0 inset #90278e20;
}
.table-browser-action:active{
  background-color: #90278e15;
  box-shadow: 0 0 6px 0 inset #90278e30;
}
.table-actions-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  .table-actions-title-section {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.browser-res-count{
  font-weight: bold;
}
.entity-browser-title{
  font-weight: bold;
}
.no-results-wrap{
  color: #666;
}
.excel-uploader-wrap {
  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  input[type=file] {
    display: none;
  }

  label{
    display: block;
    margin: 0 auto;
    width: fit-content;
  }
}
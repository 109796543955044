.json-query-editor{
    padding: 0 0.5em;
    display: block;
    border-right: 2px solid black;

    input[type="text"]{
        display: block;
        position: relative !important;
        background: white !important;
        border: 1px solid #666 !important;
    }
    label{
        display: block;
        span{
            margin-left: 0.5em;
        }
    }
    .embedded-object{
        background-color: #00000010;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1em;
        padding-right: 0.5em;
    }
    .array_entry_wrap{
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        align-items: flex-start;
        margin-bottom: 1em;
    }
    .add-array-entry-button{
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        align-items: flex-start;
    }
    .add-array-entry-button:hover{
        text-decoration: underline;
    }
    .delete-array-entry-button:hover{
        color: #c00;
    }
    .react-date-picker{
        background: white;
    }
    .filter-one-option {
        background-color: white;
        border: 1px solid #bbb;
        width: fit-content;
        padding: 1px 4px 1px 10px;
        margin-block: 2px;
    }
}
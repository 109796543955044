@import "../../../lib/variables";
.table-lister {
  .selectable-table-body {
    .selectable-table-row{
      position: relative;
      cursor: pointer;
    }
    .selected-row:after, .selectable-table-row:hover:after {
      content: '';
      position: absolute;
      width: 0.3em;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
    }
    .selectable-table-row:hover:after {
      background: $purple-dark;
    }
    .selectable-table-row:active:after {
      background: $purple-darker;
    }
    .selectable-table-row.selected-row:after{
      background: $purple-main;
    }
    .selectable-table-row.selected-row:hover:after{
      background: $purple-main-trans_08;
    }
    .selectable-table-row.selected-row:active:after{
      background: $purple-main-trans_06;
    }

    .selected-row {
      -webkit-animation: sheen 0.75s ease forwards;
      -moz-animation: sheen 0.75s ease forwards;
      animation: sheen 0.75s ease forwards;
    }
  }

  @-webkit-keyframes sheen {
    0% {
      background-position: 0 50%
    }
    100% {
      background-position: 100% 50%
    }
  }
  @-moz-keyframes sheen {
    0% {
      background-position: 0 50%
    }
    100% {
      background-position: 100% 50%
    }
  }
  @keyframes sheen {
    0% {
      background-position: 0 50%
    }
    100% {
      background-position: 100% 50%
    }
  }
}
.array-controller {
  gap: 0.5em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 1.5em;

  input {
    background: transparent;
    border: none;
    outline: none;
  }

  .array-entry {
    padding-right: 0.5em;
    border-radius: 0.2em;
    background: #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .array-entry.read-only{
    padding-left: 0.5em;
  }

  .array-entry button {
    border: none;
    outline: none;
    background: none;
    font-weight: bold;
    cursor: pointer;
    height: 100%;
    border-radius: 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-entry-button{
    height: 2em;
    width: 2em;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.array-controller.disabled{
  button:hover {
    background: transparent;
  }

  button:active {
    background: transparent;
  }

  button{
    cursor: not-allowed;
  }
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: #90278e;
  cursor: pointer;
  transition: background-color 0.25s;
}
.upload-btn-wrapper:hover{
  background-color: #731f72;
}
.upload-btn-wrapper:active{
  background-color: #561755;
}
.upload-btn {
  border: none;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.25em 0.75em;
  font-size: 1.5em;
}
.upload-btn-wrapper input[type=file] {
  cursor: pointer;
  height: 3.5em;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
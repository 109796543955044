@import "../../../lib/variables.scss";

.selectable-table {
  --border-color: #fff;

  --main-color: $cyan-main;
  --main-color-dark: $cyan-dark;
  --main-color-darker: $cyan-darker;
  --main-highlight-color: $cyan-highlight;

  --main-color-contrast: #fff;
  --field-background: #fff;
  --field-background-dark: #eee;
  --field-background-darker: #ddd;

  --param-color: #003;
}

.selectable-table-controller{
  position: absolute;
  padding: 0 0.5em;
  box-sizing: border-box;
  z-index: 2;
  outline:none;
  border: none;
  background-color: transparent;
  color: transparent;
  pointer-events: none;
}
.selectable-table{
  overflow: auto;
}
.selectable-table-header{
  position: sticky;
  top: 0;
  z-index: 10;
  .selectable-table-row, .selectable-table-cell{
    height: 2.5em;
  }
  .selectable-table-cell{
    transition: background-color 0.25s;
    background-color: #f8f1f8;
  }
  .selectable-table-cell:hover{
    background-color: #f1e4f1;
  }
}
.selectable-table-body{
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 50vh;
  flex-shrink: 0;
  .selectable-table-row:nth-child(even){
    background-color: #eaeaea;
  }
  .selectable-table-row:nth-child(odd){
    background-color: #f5f5f5;
  }
}
.selectable-table-row{
  display: flex;
  flex-direction: row;
  width: fit-content;
  min-width: 100%;
}
.selectable-table-cell{
  padding: 0 0.5em;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: 2em;
  border-bottom: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.selectable-table-cell>span{
  padding: 0 0.35em;
}

.selectable-table-cell .array-controller{
  padding: 0.5em;
}

.selectable-table-cell{
  input[type=text].fill-cell-input{
    position: absolute;
    padding: 0 0.5em;
    box-sizing: border-box;
    outline:none;
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
  }
  .react-date-picker{
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
    .react-date-picker__wrapper{
      border: none;
    }
  }
  .react-date-picker *{
    outline:none;
  }
  .json-editor{
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    input[type="text"]{
      outline: none !important;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
    .react-date-picker{
      width: fit-content;
      box-sizing: border-box;
    }

    .array_entry_wrap{
      margin-bottom: 0;
    }
    .embedded-object{
      margin-bottom: 0.25em;
    }
  }
}
.selectable-table-cell.disabled-table-cell{
  background: #ccc;
  color: #666;
}
.selectable-table-cell.grid-top{
  border-top: 1px solid var(--border-color);
}
.selectable-table-cell.grid-right{
  border-right: 1px solid var(--border-color);
}
.clickable-table-cell{
  cursor: pointer;
  *{
    pointer-events: none !important;
  }
}
.column-stretcher-wrap{
  position: relative;
  width: 0;
  height: 100%;
  z-index: 10;
}
.column-stretcher{
  position: absolute;
  width: 0.5em;
  left: -0.25em;
  top: 0;
  height: 100%;
  background: #00786a00;
  cursor: col-resize;
}
.column-stretcher:hover{
  background: #00786a50;
}
.column-stretcher:active{
  background: #00786a80;
}
.column-stretcher-wrap:last-child .column-stretcher{
  left: 0;
  width: 0.25em;
}
.selectable-table-row-actions{
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
$purple-main: #90278e;
$purple-dark: #80177e;
$purple-darker: #60076e;
$purple-light: #f8ccff;
$pink: rgb(255, 56, 131);
$cyan-main: rgb(0, 150, 136);
$cyan-dark: rgb(0, 130, 116);
$cyan-darker: rgb(0, 120, 96);
$cyan-highlight: rgba(0, 150, 136, 0.3);
$red-main: #dd0000;
$red-dark: #aa0000;
$red-darker: #880000;
$red-light: #ff5555;
$purple-main-trans_01: #90278e10;
$purple-main-trans_02: #90278e20;
$purple-main-trans_03: #90278e30;
$purple-main-trans_04: #90278e40;
$purple-main-trans_05: #90278e50;
$purple-main-trans_06: #90278e60;
$purple-main-trans_07: #90278e70;
$purple-main-trans_08: #90278e80;
$purple-main-trans_09: #90278e90;
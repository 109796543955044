.page-wrap-import-contacts {
  .file-uploader-buttons-wrap{
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  .contacts-upload-stage-title, .contacts-upload-stage-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
  .stage-nav-button{
    font-size: 1em;
    display: flex;
    gap: 1em;
    align-items: center;
    line-height: 1em;
  }
  .next-button{
    float: left;
  }
  .selectable-table-cell{
    padding: 0;
    input, select{
      border: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background: transparent;
    }
  }
  .submit-contacts-wrap{
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.search-bar-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  direction: rtl;
  border-bottom: 2px solid #888;
  width: fit-content;
}

.search-button, .search-input{
  height: 2em;
  font-size: 1.25em !important;
  box-sizing: border-box;
  border: none;
  outline: none;
}

.search-input{
  padding: 0.125em 0.5em;
  background-color: #FFF;
  line-height: 2em;
  text-align: right;
  z-index: 2;
}

.search-button {
  width: 2em;
  padding: 0.125em 0.5em;
  top: 0;
  cursor: pointer;
  background-color: #DDD;
}

.search-button:hover {
  background-color: #CCC;
}

.search-button:active {
  background-color: #AAA;
}

.search-button:focus {
  box-shadow: 0 0 2px #000000a0 inset;
}

.search-button-icon {
  color: #4c5b6d;
  width: 100%;
  height: 100%;
}
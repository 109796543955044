@import "../../lib/variables";
.field-matcher{
  display: flex;
  flex-direction: column;
  margin: 1em 0;
  gap: 0.5em;

  .field-col-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em
  }
  .file-header-field{
    width: 20vw;
    overflow: hidden;
    display: flex;
    position: relative;
    span{
      background: white;
      z-index: 1;
      padding-left: 1em;
    }
  }
  .file-header-field:after{
    content: " ...................................................................";
    z-index: 0;
    position: absolute;
    font-size: 2em;
    line-height: 0.25em;
    color: $cyan-main;
    display: flex;
    justify-content: center;
  }
  select{
    border: 1px solid $purple-darker;
  }
  select:focus{
    outline: 1px solid $cyan-main;
  }
  .matched-field{
    background: $purple-light;
  }
  .unmatched-field{
    background: #ddd;
    text-align: center;
  }
  .no-match-warning{
    color: $red-dark;
  }
}
@import "variables.scss";
@import url('https://fonts.googleapis.com/css?family=Alef:400,700|Assistant|Cabin|Cairo|Rubik&display=swap');
@font-face {
  font-family: "Abraham";
  src: url("../../public/AbrahamTRIAL-Regular.otf");
}
@font-face {
  font-family: "Abraham";
  src: url("../../public/AbrahamTRIAL-Bold.otf");
  font-weight: bold;
}
body {
  margin: 0;
  font-family: Abraham, Cabin, Cairo, Rubik, sans-serif !important;
}
input, textarea, select, button {
  font-family: Abraham, Cabin, Cairo, Rubik, sans-serif !important;
}
::selection {
  background: $purple-main;
  color: white;
}
button{
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
.leaflet-control-attribution{
  display: none !important;
}
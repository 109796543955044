@import "../../lib/variables.scss";
.logout-reminder-wrap{
  font-size: 1.25em;
  line-height: 1em;
  padding: 2em;
  a{
    color: #61dafb;
  }
}
.logout-reminder-logo{
  font-size: 4em;
  text-align: center;
}

.continue-to-login-button{
  display: block;
  margin: 0 auto;
  padding: 1% 3%;
  font-size: 1.5em;
  color: $purple-main;
  background-color: white;
  border: none;
  outline: none;
}
.continue-to-login-button {
  margin: 1em auto 0;
}

.continue-to-login-button:hover {
  color: $purple-dark;
  background-color: #DDD;
}

.continue-to-login-button:active {
  color: $purple-darker;
  background-color: #BBB;
}
.entity-card-wrap{
  label{
    display: block;
  }
  .entity-card-section-title{
    display: block;
    font-weight: bold;
    color: #666;
    margin-bottom: 1em;
  }
  .entity-card-section-fields.entity-card-section-compact{
    display: flex;
    flex-direction: row;
    gap: 2em;
  }
  .card-field-wrap{
    margin-bottom: 1em;
    input, select{
      max-width: 100%;
      outline: none;
      border-radius: 0;
      border: 1px solid black;
    }
    .react-date-picker{
      border: none;
      background: transparent;
      height: 100%;
      .react-date-picker__wrapper{
        border: none;
      }
    }
    .react-date-picker *{
      outline:none;
    }
  }
  .card-field-title{
    font-size: 1em;
    font-weight: bold;
    margin-left: 1em;
    line-height: 1em;
    display: block;
  }
  .landing-page-link {
    display: flex;
    gap: 1rem;
    font-size: 1.1rem;
    svg {
      height: 1.2rem;
    }
  }
  .slot {
    position: relative;
    .fields-indicator-wrapper {
      position: absolute;
      inset-block-start: -20rem;
      inset-inline-start: 75%;
      min-width: 24rem;
      .title {
        position: relative;
        inset-inline-start: 1rem;
        inset-block-start: 0.7rem;
        display: block;
        background-color: white;
        width: fit-content;
        padding: 0px 7px;
      }
      .fields-indicator {
        border: 2px solid black;
        padding: 2rem 1rem;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .field {
          display: flex;
          gap: 1rem;
          .underline {
            min-width: 9rem;
            display: block;
            flex-grow: 1;
            border-bottom: 1px solid black;
          }
        }
      }
    }
    .image-preview-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 0.5rem;
      img { 
        max-height: 300px;
        max-width: 50vw;
      }
      button {
        background-color: white;
        width: 2rem;
        height: 2rem;
        border-radius: 5px;
        border: 2px solid black;
      }
    }
  }

  .errors-list {
    list-style: none;
    .error {
      color: red;
    }
  }
}
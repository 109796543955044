@import "../../lib/variables.scss";
.query-builder-wrap{
  width: 100%;

  .single-condition-wrap{
    display: flex;
    flex-direction: row;
  }
}
.condition-relation, .join-condition-relation{
  height: 1.5em;
  line-height: 1.5em;
  background-color: #444;
  color: #fff;
  margin: 5px 0;
}
.join-condition-relation{
  display: block;
  width: fit-content;
}
.condition-relation:hover{
  background-color: #000;
}
.condition-relation:active{
  background-color: #034;
}

.query-controls-bar{
  display: flex;
  width: 100%;
  gap: 6px;
  border-bottom: 1px solid $purple-darker;
}

.delete-condition-button{
  align-self: start;
  margin-top: 3px;
}

.condition-builder-wrap{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: $purple-main-trans_01;
  padding: 5px;
  transition: background 0.25s;
}

.condition-builder-wrap:hover{
  background: $purple-main-trans_02;
  box-shadow: 0 0 0 1px $purple-main inset;
}

.condition-builder-wrap.selected-condition{
  box-shadow: 0 0 0 2px $purple-main inset;
}

.condition-group{
  padding: 3px 6px;
  border: 2px solid $purple-darker;
}
.add-filter-button{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border-radius: 100%;
  margin-right: 1em;
}

.joins-wrap{
  display: flex;
  flex-direction: column;
  gap: 1em;
  .join-wrap{
    background-color: #cfe9f6;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px;
    .join-query-builder-wrap{
      flex-basis: 100%;
    }
  }
}
.content-wrap{
    padding: 2em;
    h3{
        margin-top: 0;
    }
}
.horizontal-spread{
    display: flex;
    flex-direction: row;
    gap: 1em;
}
@import "../../lib/variables.scss";

html:has(.landing-page) {
  background-color: #eee;
  color: #90278e;
}

body:has(.landing-page)  {
  margin-inline: auto;
  width: 100%;
  max-width: 840px;
  min-width: 320px;
}

.landing-page {
  display: flex;
  flex-direction: column;
}

.landing-page.loading {
  display: flex;
  flex-direction: column;
  text-align: center;
  direction: rtl;
  margin: 5rem;
}
.landing-page.loading svg {
  height: 7rem;
}

.landing-page .logo {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 5rem;
}

.landing-page .cover {
  width: calc(100% + 3rem + 3px);
  align-self: center;
  box-shadow: 0px 0px 20px 10px #0001;
}

.landing-page .form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-inline: auto;
}

.landing-page .form label {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.landing-page .form label span {
  display: inline;
}

.landing-page .form input {
  padding: 8px 10px;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  border: 1px solid #232323;
  unicode-bidi: plaintext;
  transition: all 300ms ease-in-out;
}
.landing-page .form input.error {
  border: 2px solid red;
}
.landing-page .form input:hover {
  border: 1px solid #90278e;
  filter: drop-shadow(1px 3px 5px #0001);
}
.landing-page .form input:focus {
  border: 2px solid #90278e;
  filter: drop-shadow(1px 3px 5px #0001);
}

.landing-page h1 {
  margin-block: 3rem;;
  text-align: center;
}

.landing-page p {
  text-align: justify;
}

.landing-page .form button {
  color: white;
  width: fit-content;
  align-self: center;
  padding: 1rem 2rem;
  border: 1px solid white;
  font-size: 1rem;
  margin-top: 1.5rem;
  position: relative;
  background-color: #90278e;
  border-radius: 2rem;
  transition: all 200ms ease-in-out;
}
.landing-page .form button:hover {
  background-color: #bb56b9;
}

.landing-page .form button svg {
  width: 2rem;
  position: absolute;
  left: 1rem;
  top: 0;
}

.landing-page .error-msg {
  text-align: center;
  margin-block: 1rem;
  font-size: 1.5rem;
  color: red;
  white-space: pre;
}

.landing-page .success-msg {
  text-align: center;
  font-size: 1.2rem;
}

.landing-page .disclaimer {
  font-size: 0.9rem;
  margin-top: 4rem;
  text-align: center;
}
.landing-page .disclaimer button {
  color: #90278e;
  text-decoration: underline;
  padding: 0;
}
.landing-page dialog {
  width: 80%;
  max-width: 960px;
  max-height: 80%;
  line-height: 1.6rem;
  box-shadow: 2px 2px 18px 0px #0002;
  padding: 4rem 2rem;
  border: none;
}
.landing-page dialog::backdrop {
  background-color: #0006;
}

@media only screen and (max-width: 880px) {
  body:has(.landing-page)  {
    width: 90%;
  }

  .landing-page p {
    text-align: unset;
  }
  .landing-page .form {
    width: 100%;
  }
  .landing-page .form input {
    padding: 5px 8px;
  }
  .landing-page .form button {
    margin-top: 1rem;
  }
  .landing-page .error-msg {
    font-size: 1rem;
  }
  .landing-page dialog {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
  .landing-page .cover {
    width: 100vw;
  }
}

@media only screen and (max-width: 1200px) {
  .landing-page .logo {
    display: none;
  }
}

@import "../../lib/variables.scss";
.coordinates-picker-button{
  display: flex;
  align-items: center;
  gap: 1em;
  .coordinates-picker-icon{
    color: $cyan-main;
    line-height: 1em;
    height: 1em;
  }
}
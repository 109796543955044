.popup-wrapper{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
}

.popup-background{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(82, 86, 89, 0.75);
  z-index: 0;
}
.popup {
  position: relative;
  margin: 10vh auto;
  width: 50%;
  max-height: 80vh;
  padding: 2.5%;
  z-index: 1;
  box-shadow: 0 1px 5px 0 black;
  background-color: white;
  overflow-y: auto;
}
.popup-content-wrap{
  height: 100%;
  width: 100%;
  overflow: auto;
}

.popup-inverse{
  .popup{
    color: white;
    background-color: rgb(144, 39, 142);
  }
  .popup a:link {
    color: #fff;
  }
  .popup a:visited {
    color: #fff;
  }
  .popup a:hover {
    color: #fff;
  }
  .popup a:active {
    color: #fff;
  }
}

@media only screen and (max-width: 480px){
  .popup{
    width: 85%;
  }
}
.schema-editor{
    padding: 0 0.5em;
    input[type="text"]{
        display: block;
        position: relative !important;
        background: white !important;
        border: 1px solid #666 !important;
    }
    label{
        display: block;
        span{
            margin-left: 0.5em;
        }
    }
    .embedded-object{
        background-color: #00000010;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 1em;
        padding-right: 0.5em;
    }
    display: block;
    border-right: 2px solid black
}
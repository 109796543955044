.city-search-wrap{
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;

  .city-input{
    color: #888;
    border: 1px solid black;
  }
  .valid-value{
    color: #000;
    background-color: #cfc;
  }
  .add-city-button{
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    line-height: 1.5em;
    border-radius: 100%;
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-city-button:hover{
    background: #4c5b6d;
  }
}
.spinner-wrap{
  display: inline-block;
  /*credit to Luke Hass, https://projects.lukehaas.me/css-loaders/*/
  .load-spinner {
    font-size: 10px;
    text-indent: -9999em;
    border-radius: 50%;
    background: linear-gradient(to right, #90278e 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    animation: load 1.4s infinite linear;
    transform: translateZ(0);
  }
  .load-spinner:before {
    width: 50%;
    height: 50%;
    background: #90278e;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .load-spinner:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.spinner-wrap.centered{
  margin: 0 auto;
  text-align: center;
  width: fit-content;
  display: block;
}

.purple-background .load-spinner, .nav-bar .title-wrap .load-spinner {
  background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 42%);
}
.purple-background .load-spinner:before, .nav-bar .title-wrap .load-spinner:before{
  background: #fff;
}
.purple-background .load-spinner:after, .nav-bar .title-wrap .load-spinner:after {
  background: #90278e;
}